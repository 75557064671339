<template>
  <main v-if="ListMember.status != 'false'" class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">
            <i class="fas fa-project-diagram margin-r" aria-hidden="true"></i>Edit Member
          </h3>
          <!-- Forward verification mail -->
          <div class="text-end">
            <div class="cursor-pointer d-inline m-3" type="button" @click="FowardVerification"> Forward verification mail <i class="fas fa-envelope"></i>
            </div>
          </div>
          <!-- ./Forward verification mail -->
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important">
                <h6 class="text-start card-title fw-bold"> Member info </h6>
                <div class="container mt-4">
                  <form ref="ProjectForm" @submit.prevent="PostEditMember()">
                    <div class="col-md-12" align="left">
                      <label for="validationDefault01" class="form-label">Pay rate (Per Hour)</label>
                      <input type="number" step=0.01 v-model="MemberPost.pay_rate" class="form-control" id="validationDefault01" required placeholder="Pay rate">
                    </div>
                    <div class="d-flex justify-content-end mt-3 m-1">
                      <button :disabled="Form.submit" class="button-1 btn btn-primary" type="submit">Save</button>
                    </div>
                  </form>
                  <form v-if="ListRole.status != 'false'" ref="RoleForm" @submit.prevent="PostEditRole()">
                    <div class="col-md-12" align="left">
                      <label for="validationDefault01" class="form-label">User role</label>
                       <Multiselect  v-model="MultiSelectRole.value"   v-bind="MultiSelectRole"  class="size-multiselect" ref="multiselect_roles"  placeholder="Select role"></Multiselect>
                    </div>
                    <div class="d-flex justify-content-end mt-3 m-1">
                      <button :disabled="FormRole.submit" class="button-1 btn btn-primary" type="submit">Save</button>
                    </div>
                  </form>
                   <form v-if="ListProjectMember.status != 'false'" ref="ProjectForm" @submit.prevent="PostEditMemberPayProject()">
                    <div class="col-md-12" align="left">
                      <label for="validationDefault01" class="form-label">Pay rate for project (Per Hour)</label>
                       <Multiselect  v-model="MultiSelectProject.value"  v-on:click="SearchPayRate(MultiSelectProject.value)"  v-bind="MultiSelectProject"  class="size-multiselect"   placeholder="Select project"></Multiselect>
                    </div>
                    <div class="col-md-12 mt-3" align="left">
                      <input  type="number" step=0.01 v-model="ListProjectMember.pay_rate" class="form-control" id="validationDefault01" required placeholder="Pay rate">
                    </div>
                    <div class="d-flex justify-content-end mt-3 m-1">
                      <button :disabled="FormPayRate.submit" class="button-1 btn btn-primary" type="submit">Save</button>
                    </div>
                  </form>
                  <!--<hr>
                  <div class="col-md-12" align="right">
                    <button class="button-1 btn btn-primary" type="button" @click="FowardVerification">Forward verification mail</button>
                  </div>
                  <hr>-->
                  <div class="col-md-12 mt-4 mb-2" align="right">
                    <a class="btn btn-danger"   style="margin-right: 5px;" @click="$router.go(-1)"> Go Back</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Api from '@/services/Request'; 
import Multiselect from '@vueform/multiselect'

  export default
  {
    name: 'ContentEditMember',
    components: 
    {
      Multiselect,
    },
    data: () => (
    {
      //Foward Verification
      MemberSessionPost:
      {
        id_session: ""
      },
      MemberPost: 
      {
        id:"",
        pay_rate:""
      },
      MemberPostPayProject: 
      {
        id:"",
        id_project:"",
        pay_rate:""
      },
      ListMember: 
      {
        id:"",
        status:""
      },
      ListRole: 
      {
        id:"",
        type:"",
        status:""
      },
       ListProjectMember: 
      {
        id:"",
        status:"false",
        pay_rate:""
      },
      MultiSelectProject: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        hideSelected: true,
        showOptions: true,
        required: true
      },
      MultiSelectRole: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        hideSelected: true,
        showOptions: true,
        required: true
      },
      Form: 
      {
        submit: true
      },
      FormRole: 
      {
        submit: true
      },
      FormPayRate: 
      {
        submit: true
      }
    }),
    methods: 
    {
      //Foward Verification Mail
      FowardVerification()
      {
        this.MemberSessionPost.id_session = this.$route.params.id;

        Api.post("/login/verification",this.MemberSessionPost).then((result) => 
        {
          this.$moshaToast( ''+result.data.msg+'', { type: 'default', position:'bottom-right', transition:'zoom' })
        })
      },
      PostEditMember() 
      {
        Api.post("/member/edit",this.MemberPost).then((result) => 
        {
          this.Form.submit = true

          if(result.data.status == "true")
          {
            this.$moshaToast( ''+this.$filters.OnlyLetters(result.data.msg)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
        
            setTimeout( () => 
            {
              this.Form.submit = false
            }, 5000);
          }
        })    
      },
      GetMember() 
      {
        Api.get('/member/list/' + this.$route.params.id).then((result) => 
        {
          this.ListMember.status = result.data.status

          if(this.ListMember.status == "true")
          {
            this.MemberPost.id =  result.data.member[0].user_id
            this.MemberPost.pay_rate =  result.data.member[0].pay_rate
            this.ListRole.type =  result.data.member[0].type
            this.Form.submit = false
            this.FormPayRate.submit = false
            this.GetRoles()
          }
        })
      },
      GetMembersProject() 
      {
        Api.get('/projects-members/memberproject/' + this.$route.params.id).then((result) => 
        {
          if (result.data.status == "true") 
          {
            if (result.data.memberprojects != "") 
            {
              var membersprojects = []
              membersprojects = result.data.memberprojects
              let myprojects = [];

              for (var i = 0; i < membersprojects.length; i++) 
              {
                myprojects.push(
                {
                  value: membersprojects[i].id_project ,
                  label: membersprojects[i].name,
                  pay_rate: membersprojects[i].pay_rate
                });
              }

              this.MultiSelectProject.options = myprojects;

              this.ListProjectMember.status = result.data.status
    
            }
          }
        });

      },
      SearchPayRate(SelectValue) 
      {
        if(SelectValue != "" && SelectValue != null )
        {
          let position = [];

          for (var i = 0; i < this.MultiSelectProject.options.length; i++) 
          {
          position.push(this.MultiSelectProject.options[i].value)
          }
      
          let array = position
          let index = array.indexOf(SelectValue);

          if(this.MultiSelectProject.options[index].pay_rate != "")
            {
              this.ListProjectMember.pay_rate = this.MultiSelectProject.options[index].pay_rate
            }
            else
            {
              this.ListProjectMember.pay_rate = ""
            }
        }
      },
      PostEditMemberPayProject() 
      {
        let Post = 
        {
          id: this.MemberPost.id ,
          id_project: this.MultiSelectProject.value,
          pay_rate: this.ListProjectMember.pay_rate
        };


        Api.post("/projects-members/payrate",Post).then((result) => 
        {
          this.FormPayRate.submit = true

          if(result.data.status == "true")
          {
            this.$moshaToast( ''+this.$filters.OnlyLetters(result.data.msg)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
            
            setTimeout( () => 
            {
               this.FormPayRate.submit= false
            }, 5000);


          }
        })    
      },
      PostEditRole() 
      {
        var answer = window.confirm("Are you sure you want to change the role for this user?")
        if (answer) 
        {
          let Post = 
          {
            id_session: this.MemberPost.id ,
            type: this.MultiSelectRole.value,
          };


          Api.post("/member/update/role",Post).then((result) => 
          {
            this.FormRole.submit = true

            if(result.data.status == "true")
            {
              this.$moshaToast( ''+this.$filters.OnlyLetters(result.data.msg)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
              
              setTimeout( () => 
              {
                this.FormRole.submit= false
              }, 5000);

            }
            if(result.data.status == "false")
            {
              this.$moshaToast( ''+this.$filters.OnlyLetters(result.data.msg)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
              
              setTimeout( () => 
              {
                this.FormRole.submit= false
              }, 5000);

            }
        
          }) 
        }   
      },
      GetRoles() 
      {
        Api.get('/role/list/').then((result) => 
        {
          if (result.data.status == "true") 
          {
            if (result.data.roles != "") 
            {
              this.$refs.multiselect_roles.clear()

              var allroles = []
              allroles = result.data.roles 
              let myroles = [];

              for (var i = 0; i <  allroles.length; i++) 
              {
                myroles.push(
                {
                  value: allroles[i].type ,
                  label: allroles[i].description,
                });
              }


              this.MultiSelectRole.options = myroles;

              for (var i = 0; i <  this.MultiSelectRole.options.length; i++) 
              {
              var position=  this.MultiSelectRole.options[i].value.indexOf(this.ListRole.type)

               if(position == 0)
               {
                 var rolePosition = i
               }
              }
        
              this.$refs.multiselect_roles.select(this.MultiSelectRole.options[rolePosition])

              this.ListRole.status = result.data.status
              this.FormRole.submit = false
    
            }
          }
        });

      },
    },
    mounted()
    {
      this.GetMember()
      this.GetMembersProject() 
   
    }
  }
</script>

<style></style>
